import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  CButton,
  CCard,
  CCardBody,
  CRow,
  CForm,
  CCol,
  CFormTextarea,
  CFormSelect,
} from "@coreui/react";
import { useMutation } from "react-query";
import { getCategory } from "src/api/services/Categroy";
import { getAllSports } from "src/api/services/Sports";
import { getAllStates } from "src/api/services/States";
import { useNavigate } from "react-router-dom";
import style from "./brokenRecords.module.css";
import axios from "axios";

export default function BrokenRecords() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nsrs_game_event_name: "",
      nsrs_sport_id: "",
      sport_name: "",
      state: "",
      meet_record: "",
      national_record: "",
      world_record: "",
      player_team_name: "",
      player_or_team: "",
      remarks: "",
      nsrs_game_event_id: "",
    },
  });

  const [stateList, setStateList] = React.useState([]);
  const [sportList, setSportList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [EventNames, setEventNames] = React.useState([]);

  // API Mutations
  const getCategoryAPI = useMutation({
    mutationKey: ["getCategory"],
    mutationFn: (params) => getCategory(params),
    onSuccess: (data) => {
      if (data.totalRecord > 0) {
        setCategoryList(data.records);
      }
    },
    onError: handleError,
  });

  const getStatesAPI = useMutation({
    mutationKey: ["getAllStates"],
    mutationFn: (params) => getAllStates(params),
    onSuccess: (data) => {
      if (data.totalRecords > 0) {
        setStateList(data.data);
      }
    },
    onError: handleError,
  });

  const getSportsAPI = useMutation({
    mutationKey: ["getAllSports"],
    mutationFn: (params) => getAllSports(params),
    onSuccess: (data) => {
      if (data.totalRecords > 0) {
        setSportList(data.data);
      }
    },
    onError: handleError,
  });

  useEffect(() => {
    document.title = "Add News";
    const params = { page: 1, limit: 100, sort: 1, search: "" };
    getStatesAPI.mutate(params);
    getSportsAPI.mutate(params);
    getCategoryAPI.mutate(params);
  }, []);

  function handleError(errObj) {
    const errMessage = errObj.response.data.message;
    toast.error(errMessage, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  //Due to some instant changes please ignore the stability for now
  const onSubmit = (data) => {
    // console.log(data);
    let sportID = data.nsrs_sport_id;
    sportID = sportList.find((ele) => ele.sport_id == sportID);
    let eventID = data.nsrs_game_event_id;
    eventID = EventNames.find((ele) => ele.sport_event_id == eventID);
    //assign
    data.sport_name = sportID?.sport_name;
    data.nsrs_game_event_name = eventID?.nsrs_game_event_name;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}media/admin/addbrokenrecords`,
        data
      )
      .then((response) => {
        if (response?.status == 200) {
          toast.success("Record added successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          console.log("first");
          navigate("/app/brokenRecords/add");
        } else {
          toast.error(response.data.message || "Failed to add record", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Something went wrong";
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.error("Error adding broken record:", error);
      });
  };
  const selectedSportId = watch("nsrs_sport_id");
  //
  useEffect(() => {
    if (selectedSportId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}media/admin/getEvents/${selectedSportId}`
        )
        .then((response) => {
          if (response.status) {
            setEventNames(response?.data?.data?.records);
          }
        })
        .catch((error) => {
          console.error("Error fetching events:", error);
        });
    } else {
      setEventNames([]);
    }
  }, [selectedSportId]);

  return (
    <CCard>
      <CCardBody className={`${style.addNewsContainer} d-flex flex-column`}>
        <h4>Add Broken Records</h4>
        <CCard className="mb-2 d-flex justify-content-end">
          <CCardBody className="d-flex justify-content-end">
            <CButton onClick={() => navigate(-1)} color="primary">
              Go Back
            </CButton>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CForm onSubmit={handleSubmit(onSubmit)}>
              <CRow className={`${style.formBox} d-flex`}>
                <CCol sm={12} md={4}>
                  <CFormSelect
                    id="state"
                    aria-label="States"
                    label="State"
                    {...register("state", { required: "State is required" })}
                    invalid={!!errors.state}
                    feedback={errors.state?.message}
                  >
                    <option value="">Choose State...</option>
                    {stateList.map((item) => (
                      <option key={item.state_id} value={item.state_id}>
                        {item.state_name}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>

                <CCol sm={12} md={4}>
                  <CFormSelect
                    id="sport"
                    aria-label="Sport"
                    label="Sport"
                    {...register("nsrs_sport_id", {
                      required: "Sport is required",
                    })}
                    invalid={!!errors.nsrs_sport_id}
                    feedback={errors.nsrs_sport_id?.message}
                  >
                    <option value="">Choose Sport...</option>
                    {sportList.map((item) => (
                      <option key={item.sport_id} value={item.sport_id}>
                        {item.sport_name}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>

                <CCol sm={12} md={4}>
                  <CFormSelect
                    id="event_name"
                    aria-label="event name"
                    label="Event Name"
                    {...register("nsrs_game_event_id", {
                      required: "Event Name is required",
                    })}
                    invalid={!!errors.nsrs_game_event_id}
                    feedback={errors.nsrs_game_event_id?.message}
                  >
                    <option value="">Choose Event Name...</option>
                    {selectedSportId &&
                      EventNames.map((item) => (
                        <option
                          key={item.sport_event_id}
                          value={item.sport_event_id}
                        >
                          {item.nsrs_game_event_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>

                <CCol sm={12} md={6}>
                  <CFormTextarea
                    id="meet_record"
                    rows={1}
                    label="Meet Record"
                    {...register("meet_record")}
                    invalid={!!errors.meet_record}
                    feedback={errors.meet_record?.message}
                  />
                </CCol>

                <CCol sm={12} md={6}>
                  <CFormTextarea
                    id="national_record"
                    rows={1}
                    label="National Record"
                    {...register("national_record")}
                    invalid={!!errors.national_record}
                    feedback={errors.national_record?.message}
                  />
                </CCol>

                <CCol sm={12} md={6}>
                  <CFormTextarea
                    id="world_record"
                    rows={1}
                    label="World Record"
                    {...register("world_record")}
                    invalid={!!errors.world_record}
                    feedback={errors.world_record?.message}
                  />
                </CCol>

                <CCol sm={12} md={6}>
                  <CFormSelect
                    id="player_or_team"
                    aria-label="Player or Team"
                    label="Individual Athlete/Team"
                    {...register("player_or_team")}
                    invalid={!!errors.player_or_team}
                    feedback={errors.player_or_team?.message}
                  >
                    <option value="">Choose Player or Team...</option>
                    <option value="player">Player</option>
                    <option value="team">Team</option>
                  </CFormSelect>
                </CCol>

                <CCol sm={12} md={6}>
                  <CFormTextarea
                    id="player_team_name"
                    rows={1}
                    label="Individual Athlete/Team Name"
                    {...register("player_team_name")}
                    invalid={!!errors.player_team_name}
                    feedback={errors.player_team_name?.message}
                  />
                </CCol>

                <CCol sm={12} md={6}>
                  <CFormTextarea
                    id="remarks"
                    rows={2}
                    label="Remarks"
                    {...register("remarks")}
                    invalid={!!errors.remarks}
                    feedback={errors.remarks?.message}
                  />
                </CCol>

                <div className="mt-5 d-flex justify-content-end align-items-center">
                  <CButton type="submit">Save</CButton>
                </div>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCardBody>
    </CCard>
  );
}
