import axios from '../../axios'

// ** Function to get all states
const getAllStates = async (params) => {
  const res = await axios.get(
    `/state/all?search=${params.search}&limit=${params.limit}&sort=${params.sort}&page=${params.page}`,
  )
  return res?.data
}

export { getAllStates }
