import React, { useEffect, useState } from "react";
import {
  CContainer,
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CButton,
} from "@coreui/react";
import Pagination from "src/components/Paginations/paginations";
import CustomLoader from "../UI/Loader/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_BASE_URL;

export default function BrokenRecordsListing() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [page, setPage] = useState({ currentPage: 1, pageLimit: 10 });
  const limit = 10;
  const navigate = useNavigate();
  const fetchData = async () => {
    setIsLoading(true);
    try {
      console.log(page);
      const response = await axios.get(
        `${SERVER_URL}media/admin/getBrokenRecords?page=${page?.currentPage}&limit=${limit}`
      );

      if (response.status === 200) {
        setData(response?.data);
      } else {
        toast.error(response.data.data.message || "Failed to fetch records", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Error fetching records";
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [page]); // Refetch when page changes

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      try {
        const response = await fetch(
          `${SERVER_URL}media/admin/deleteBrokenRecords/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to delete the record");
        }

        // Assuming successful deletion, update the state
        alert("Record deleted successfully!");
        fetchData();
      } catch (error) {
        console.error("Error deleting record:", error);
        alert("Failed to delete the record. Please try again.");
      }
    }
  };
  if (isLoading)
    return (
      <CCard>
        <CCardBody className="flex justify-content-center align-items-center">
          <CustomLoader />
        </CCardBody>
      </CCard>
    );

  return (
    <CContainer fluid>
      <CCard className="mb-2 d-flex justify-content-end">
        <CCardBody className="d-flex justify-content-end">
          <CButton
            onClick={() => navigate("/app/brokenRecords/add")}
            color="primary"
          >
            Add Broken Records
          </CButton>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardBody className="d-flex flex-column" style={{ gap: "12px" }}>
          {data?.data?.records?.length > 0 ? (
            <>
              <CTable responsive striped>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>S.No</CTableHeaderCell>
                    <CTableHeaderCell>Event Name</CTableHeaderCell>
                    <CTableHeaderCell>Sport Name</CTableHeaderCell>
                    <CTableHeaderCell>Meet Record</CTableHeaderCell>
                    <CTableHeaderCell>National Record</CTableHeaderCell>
                    <CTableHeaderCell>World Record</CTableHeaderCell>
                    <CTableHeaderCell>Player/Team Name</CTableHeaderCell>
                    <CTableHeaderCell>Player or Team</CTableHeaderCell>
                    <CTableHeaderCell>Remarks</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {data?.data?.records?.map((item, index) => (
                    <CTableRow key={item.id}>
                      <CTableDataCell>
                        {(+page.currentPage - 1) * +limit + index + 1}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.nsrs_game_event_name || "-"}
                      </CTableDataCell>
                      <CTableDataCell>{item?.sport_name || "-"}</CTableDataCell>
                      <CTableDataCell>
                        {item?.meet_record || "-"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.national_record || "-"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.world_record || "-"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.player_team_name || "-"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.player_or_team || "-"}
                      </CTableDataCell>
                      <CTableDataCell>{item?.remarks || "-"}</CTableDataCell>

                      <CTableDataCell>
                        <CButton
                          color="danger"
                          size="sm"
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>

              {/* Pagination Component */}
              <Pagination
                totalRecords={data?.data?.total || 0}
                pageLimit={limit}
                currentPage={page.currentPage}
                pageNeighbours={1}
                setPage={setPage}
              />
            </>
          ) : (
            <CCard className="mt-3">
              <CCardBody>
                <h5 className="text-center">
                  No Broken Records available at this moment
                </h5>
              </CCardBody>
            </CCard>
          )}
        </CCardBody>
      </CCard>
    </CContainer>
  );
}
