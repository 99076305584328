import axios from "../../axios.js";
// * Helpers
import { multiPartData } from "src/helpers/index.js";
// import { BASE_URL } from "src/config/config.js";
const BASE_URL = process.env.REACT_APP_BASE_URL; 
// * Function to add news
const addCategory = async (Payload) => {
  const res = await axios.post(`${BASE_URL}news/category/add`, Payload);
  return res?.data;
};

// ** Function to get all news
const getCategory = async (params) => {
  const res = await axios.get(
    `/news/category/admin/all?search=${params.search}&limit=${params.limit}&sort=${params.sort}&page=${params.page}`
  );
  return res?.data;
};

// ** Function to get news Details
const getCategoryById = async (newsId) => {
  const res = await axios.get(`/news/category/details/${newsId}`);
  return res?.data;
};

// * Function to edit news
const editCategory = async (Payload) => {
  const res = await axios.post(`${BASE_URL}news/category/edit`, Payload);
  return res?.data;
};

// * Function to delete news
const deleteCategory = async (newsId) => {
  const res = await axios.get(`${BASE_URL}news/category/delete/${newsId}`);
  return res?.data;
};

// ** Functon to update status of news
const updateCategory = async (newsId, Obj) => {
  const res = await axios.post(
    `${BASE_URL}news/category/update/${newsId}`,
    Obj
  );
  return res?.data;
};

export {
  addCategory,
  editCategory,
  getCategory,
  deleteCategory,
  getCategoryById,
  updateCategory,
};
